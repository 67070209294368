import React, { useState, useEffect } from 'react';
import { fetch, getPostsUrl, createSlug, API_PATH } from '../utils/dao';
import marked from 'marked';

// Styling
import styled from 'styled-components';
import { font, media } from '../constants/theme';

// Components
import {
  Layout,
  Container,
  NewsPost,
  Card,
} from '../components';

// Data
import data from '../data/pages/post.json';

const PageRow = styled.div`
  @media ${media.desktop} {
    display: flex;

    .news-content {
      margin-top: -20rem;
    }

    .news-sidebar {
      width: 380px;
      margin-left: 3rem;
      flex-shrink: 0;
    }
  }

  @media ${media.notDesktop} {
    .news-content {
      margin-top: -6rem;
    }
  }

  h2 {
    font-size: ${font.size.cardHeading.default};
  }
`;

const Post = () => {
  const [post, setPost] = useState({});
  const [latestPosts, setLatestPosts] = useState([]);
  const postId =
    typeof window !== `undefined`
      ? window.location.pathname.split('/').filter(Boolean)[1]
      : null;
  const url = `${getPostsUrl()}/${postId}`;

  const params = {
    _limit: 5,
    _start: 0,
    _sort: 'published_at:desc',
  };

  const getPost = () => {
    fetch(url).then((post) => {
      if (post.title && data.content) data.content.hero.title = post.title;
      setPost(post);
    });
  };

  const getLatestPosts = (next) => {
    fetch(getPostsUrl(params, next)).then((data) => {
      const filtered = data.filter((p) => p.id !== postId);
      setLatestPosts(filtered);
    });
  };

  useEffect(() => {
    getPost();
    getLatestPosts();
  }, []);

  return (
    <>
      {Object.keys(post).length > 0 && (
        <Layout pageContext={data} heroProps={{ extraHeight: true, smallerFont: true }}>
          <Container>
            <PageRow>
              <div className="news-content">
                <NewsPost
                  title={post.title}
                  dateTimeProps={{ date: post.published_at }}
                  shareProps={{ url: window.location.href, text: post.title }}
                  imagePath={`${API_PATH}${post.image.formats.medium.url}`}>
                  {post.contents.map((content, i) => {
                    const html =
                      content.__component === 'editor.text'
                        ? marked(content.value)
                        : content.value;
                    return (
                      <div key={i} className="news-body">
                        <div dangerouslySetInnerHTML={{ __html: html }} />
                      </div>
                    );
                  })}
                </NewsPost>
              </div>
              <div className="news-sidebar">
                <h2>More News & Insights</h2>
                {latestPosts &&
                  latestPosts.length > 0 &&
                  latestPosts.map((post) => (
                    <Card
                      key={post.id}
                      condensed
                      gutter={{ bottom: '1rem', top: '1rem' }}
                      styleType="news"
                      title={post.title}
                      date={post.published_at}
                      imagePath={`${API_PATH}${post.image.formats.small.url}`}
                      cta={{
                        src: `/post/${post.id}/${createSlug(post.title)}`,
                      }}
                    />
                  ))}
              </div>
            </PageRow>
          </Container>
        </Layout>
      )}
    </>
  );
};

export default Post;
